export const PriorityEnum = Object.freeze({
  LOW: { label: 'Low', value: 1 },
  MEDIUM: { label: 'Medium', value: 2 },
  HIGH: { label: 'High', value: 3 },
});

export const priorityList = [...Object.values(PriorityEnum)];

export const getPriorityByValue = (val) =>
  priorityList.find((el) => el.value === val);

export const getPriorityName = (val = null) => {
  if (val === null) return '';

  return getPriorityByValue(val).label;
};
