export const PlanningAndResultTypeEnum = Object.freeze({
  PPC: { label: 'PPC', value: 'PPC' },
  CPM: { label: 'CPM', value: 'CPM' },
});

export const planningAndResultTypeList = [
  ...Object.values(PlanningAndResultTypeEnum),
];

export const getPlanningAndResultTypeByValue = (val = null) => {
  if (val === null) return '';

  return planningAndResultTypeList.find((el) => el.value === val);
};

export const getPlanningAndResultTypeByName = (val = null) => {
  if (val === null) return '';

  return getPlanningAndResultTypeByValue(val);
};

export const getPlanningAndResultTypeName = (val = null) => {
  if (val === null) return '';

  return getPlanningAndResultTypeByValue(val).label;
};
