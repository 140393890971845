<template>
  <vx-card
    :title="data.channel"
    title-color="#fff"
    card-background="primary"
    content-color="#fff"
    class="mb-4"
  >
    <feather-icon
      v-if="ableToEditCampaign(userId)"
      icon="TrashIcon"
      svg-classes="h-5 w-5"
      class="close-icon"
      @click="confirmDelete(data.id, data.channel)"
    />
    <feather-icon
      v-if="ableToEditCampaign(userId)"
      icon="EditIcon"
      svg-classes="h-5 w-5"
      class="edit-icon"
      @click="handleEdit"
    />
    <feather-icon
      v-if="ableToEditCampaign(userId)"
      icon="MoveIcon"
      svg-classes="h-5 w-5"
      class="move-icon"
      @click="openMoveConfirmation"
    />
    <vs-prompt
      :active.sync="activeMovePrompt"
      :is-valid="Boolean(targetTab)"
      title="Change Planning & Result placement"
      accept-text="Save"
      @cancel="closeMoveConfirmation"
      @accept="showSelected"
      @close="closeMoveConfirmation"
    >
      <div class="con-exemple-prompt">
        <p class="grey mt-2 mb-5">Select tab to which move planning & result</p>
        <v-select
          v-model="targetTab"
          name="target-tab"
          :clearable="false"
          font="small"
          :options="tabOptions"
        />
      </div>
    </vs-prompt>
    <div class="vx-row">
      <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
        <p class="vs-input--label pl-0 text-white"><b>Type:</b></p>
        <p>{{ data.type }}</p>
        <p class="vs-input--label pl-0 text-white mt-2"><b>Budget:</b></p>
        <p>{{ currency(data.budget) }}</p>
        <p class="vs-input--label pl-0 text-white mt-2"><b>Start date:</b></p>
        <p>{{ data.start_date }}</p>
        <p class="vs-input--label pl-0 text-white mt-2"><b>End date:</b></p>
        <p>{{ data.end_date }}</p>
        <p class="vs-input--label pl-0 text-white mt-2"><b>Engagement:</b></p>
        <p>Comments: {{ data.comments }}</p>
        <p>Shares: {{ data.shares }}</p>
        <p>Likes: {{ data.likes }}</p>
      </div>
      <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
        <p v-if="!isPPC" class="vs-input--label pl-0 text-white">
          <b>Impressions:</b>
        </p>
        <p v-if="!isPPC">{{ data.impressions }}</p>
        <p class="vs-input--label pl-0 text-white mt-2"><b>Clicks:</b></p>
        <p>{{ data.clicks | numInt }}</p>
        <p class="vs-input--label pl-0 text-white mt-2"><b>CTR:</b></p>
        <p>{{ data.ctr | percentage }}</p>
        <p class="vs-input--label pl-0 text-white mt-2"><b>CPC:</b></p>
        <p>{{ currency(data.cpc) }}</p>
      </div>
      <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
        <p class="vs-input--label pl-0 text-white"><b>CR:</b></p>
        <p>{{ data.cr | percentage }}</p>
        <p class="vs-input--label pl-0 text-white mt-2"><b>Units sold:</b></p>
        <p>{{ data.units_sold | numInt }}</p>
        <p class="vs-input--label pl-0 text-white mt-2">
          <b>Price per unit:</b>
        </p>
        <p>{{ currency(data.price_per_unit) }}</p>
        <p class="vs-input--label pl-0 text-white mt-2"><b>CPA:</b></p>
        <p>{{ currency(data.cpa) }}</p>
      </div>
      <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
        <p class="vs-input--label pl-0 text-white"><b>Revenue:</b></p>
        <p>{{ currency(data.revenue) }}</p>
        <p class="vs-input--label pl-0 text-white mt-2"><b>ROAS:</b></p>
        <p>{{ data.roa | percentage }}</p>
      </div>
    </div>
  </vx-card>
</template>

<script>
import { PlanningAndResultTypeEnum } from '@/enums/PlanningAndResultTypeEnum';

import vSelect from 'vue-select';

import changePlanningAndResultTabMixin from '@/mixins/changePlanningAndResultTabMixin';
import confirmationMixin from '@/mixins/confirmationMixin';
import manageCampaignMixin from '@/mixins/manageCampaignMixin';
import useCurrencyMixin from '@/mixins/useCurrencyMixin';
import { PlanningAndResulTabEnum } from '@/enums/PlanningAndResultTabEnum';

export default {
  components: {
    vSelect,
  },
  mixins: [
    changePlanningAndResultTabMixin,
    confirmationMixin,
    manageCampaignMixin,
    useCurrencyMixin,
  ],
  props: {
    data: {
      type: Object,
      required: true,
    },
    userId: {
      type: [Number, null],
      default: null,
    },
  },
  data() {
    return {
      tabName: PlanningAndResulTabEnum.SOCIAL_MEDIA.value,
    };
  },
  computed: {
    isPPC() {
      return this.data.type === PlanningAndResultTypeEnum.PPC.label;
    },
  },
  methods: {
    handleRefresh() {
      this.$emit('refresh');
    },
    handleEdit() {
      this.$emit('edit', this.data);
    },
    deleteData(id) {
      this.$emit('delete', id);
    },
  },
};
</script>

<style lang="scss" scoped>
.close-icon,
.edit-icon,
.move-icon {
  position: absolute !important;
  cursor: pointer;
}

.close-icon {
  top: -23px;
  right: 20px;
}

.edit-icon {
  top: -23px;
  right: 50px;
}

.move-icon {
  top: -23px;
  right: 80px;
}
</style>
