<template>
  <vs-sidebar
    v-model="isActive"
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="vs-sidebar__wrapper"
    spacer
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ titleType }} RESULT</h4>
      <feather-icon
        icon="XIcon"
        class="cursor-pointer"
        @click.stop="isActive = false"
      />
    </div>

    <vs-divider class="mb-0" />

    <component
      :is="scrollbarTag"
      :settings="settings"
      class="vs-sidebar__inner"
    >
      <!-- SPENT -->
      <div>
        <vs-label text="Spent ($)" />
        <vs-input
          v-model="dataSpent"
          v-validate="'required'"
          class="w-full"
          name="spent"
          type="number"
          step="0.01"
        />
        <v-error :err="errors" field="spent" />
      </div>

      <!-- IMPRESSIONS -->
      <div class="mt-4">
        <vs-label text="Impressions" />
        <vs-input
          v-model="dataImpressions"
          v-validate="'required'"
          class="w-full"
          name="impressions"
          type="number"
          step="1"
        />
        <v-error :err="errors" field="impressions" />
      </div>

      <!-- CLICKS -->
      <div class="mt-4">
        <vs-label text="Clicks" />
        <vs-input
          v-model="dataClicks"
          v-validate="'required'"
          class="w-full"
          name="clicks"
          type="number"
          step="1"
        />
        <v-error :err="errors" field="clicks" />
      </div>

      <!-- Units sold -->
      <div class="mt-4">
        <vs-label text="Units sold" />
        <vs-input
          v-model="dataUnitsSold"
          v-validate="'required'"
          class="w-full"
          name="units_sold"
          type="number"
          step="0.01"
        />
        <v-error :err="errors" field="units_sold" />
      </div>

      <!-- PRICE PER UNIT -->
      <div class="mt-4">
        <vs-label text="Price per unit ($)" />
        <vs-input
          v-model="dataPricePerUnit"
          v-validate="'required'"
          class="w-full"
          name="price_per_unit"
          type="number"
          step="0.01"
        />
        <v-error :err="errors" field="price_per_unit" />
      </div>

      <!-- BUTTONS -->
      <sidebar-buttons
        :valid="isFormValid"
        @click="handleSubmit"
        @cancel="handleClose"
      />
    </component>
  </vs-sidebar>
</template>

<script>
import { mapActions } from 'vuex';
import Datepicker from 'vuejs-datepicker';

import { CampaignAction } from '@/store/actionTypes';

import MathUtil from '@/utils/MathUtil';
import campaignRouteParamsMixin from '@/mixins/campaignRouteParamsMixin';
import sidebarMixin from '@/mixins/sidebarMixin';

import vError from '@/components/VError';

export default {
  components: {
    Datepicker,
    vError,
  },
  mixins: [campaignRouteParamsMixin, sidebarMixin],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    statusSearch: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dataId: null,
      dataSpent: 0,
      dataImpressions: 0,
      dataClicks: 0,
      dataUnitsSold: 0,
      dataPricePerUnit: 0,
      dataCtr: 0,
    };
  },
  computed: {
    campaignProductId() {
      return +this.$route.params.productId;
    },
    isFormValid() {
      return !this.errors.any();
    },
  },
  watch: {
    active(val) {
      if (!val) {
        return;
      }

      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        const {
          id,
          spent,
          impressions,
          clicks,
          units_sold,
          price_per_unit,
          ctr,
        } = this.data;

        this.dataId = id;
        this.dataSpent = spent;
        this.dataImpressions = impressions;
        this.dataClicks = clicks;
        this.dataUnitsSold = units_sold;
        this.dataPricePerUnit = price_per_unit;
        this.dataCtr = MathUtil.round(ctr);

        this.initValues();
      }
    },
  },
  methods: {
    ...mapActions('campaign', [
      CampaignAction.updateDisplayAndPaidSearchResult,
    ]),

    initValues() {
      if (this.data.id) {
        return;
      }

      this.dataId = null;
      this.dataSpent = 0;
      this.dataImpressions = 0;
      this.dataClicks = 0;
      this.dataUnitsSold = 0;
      this.dataPricePerUnit = 0;
      this.dataCtr = 0;
    },
    async handleSubmit() {
      if (!(await this.$validator.validateAll())) {
        return;
      }

      if (this.shouldUpdate) {
        this.$vs.loading();

        try {
          const obj = this.addProperIdToSubmitObject({
            id: this.dataId,
            spent: this.dataSpent,
            impressions: this.dataImpressions,
            clicks: this.dataClicks,
            units_sold: this.dataUnitsSold,
            price_per_unit: this.dataPricePerUnit,
            ctr: this.dataCtr,
          });

          const result = await this.updateDisplayAndPaidSearchResult(obj);

          this.$emit('update', result);

          this.notifySuccess(
            'Display and Paid Search Result updated',
            'The display and paid search result has been successfully updated.'
          );
        } catch (error) {
          this.notifyError(
            error,
            'Problem with updating a display and paid search result.'
          );
        }

        this.$vs.loading.close();
      }

      this.$emit('close');
      this.initValues();
    },
  },
};
</script>
