export default {
  computed: {
    hasProductId() {
      return 'productId' in this.$route.params;
    },
    hasProductGroupId() {
      return 'productGroupId' in this.$route.params;
    },
    campaignProductId() {
      if (this.hasProductId) {
        return +this.$route.params.productId;
      }

      return null;
    },
    campaignProductGroupId() {
      if (this.hasProductGroupId) {
        return +this.$route.params.productGroupId;
      }

      return null;
    },
  },
  methods: {
    addProperIdToSubmitObject(obj) {
      const newObj = { ...obj };

      if (this.hasProductId) {
        newObj.campaign_product_id = this.campaignProductId;
      }

      if (this.hasProductGroupId) {
        newObj.campaign_product_group_id = this.campaignProductGroupId;
      }

      return newObj;
    },
  },
};
