<template>
  <data-list-view-wrapper>
    <div id="campaign-config-page">
      <div class="flex items-start justify-between">
        <div>
          <h3 v-show="campaignName" class="mb-4">
            Campaign:
            <router-link :to="`/campaigns/${campaign.id}`">
              {{ campaignName }}
            </router-link>
          </h3>
          <h6 v-show="product" class="mb-4">
            <span v-if="hasProductId">Product: {{ product.name }}</span>
            <span v-else>Product group: {{ groupName }}</span>
          </h6>
        </div>
        <div class="flex column items-center md:justify-center justify-start">
          <div class="btn-group">
            <tab-btn
              title="Planning"
              :active="isActiveTab(Tabs.Planning)"
              @click="setActiveTab(Tabs.Planning)"
            />
            <tab-btn
              title="Summary"
              :active="isActiveTab(Tabs.Summary)"
              @click="setActiveTab(Tabs.Summary)"
            />
          </div>
        </div>
      </div>

      <status-search-sidebar
        :active="statusSearchSidebarOpened"
        :data="statusSearchSidebarData"
        :status-search="statusSearch"
        @close="toggleStatusSearchSidebar"
        @create="addToStatusSearchList"
        @update="updateStatusSearchList"
      />

      <display-and-paid-search-planning-sidebar
        :active="displayAndPaidSearchPlanningSidebarOpened"
        :data="displayAndPaidSearchPlanningSidebarData"
        @close="toggleDisplayAndPaidSearchPlanningSidebar"
        @create="addToDisplayAndPaidSearchPlanningList"
        @update="updateDisplayAndPaidSearchPlanningList"
      />

      <display-and-paid-search-result-sidebar
        :active="displayAndPaidSearchResultSidebarOpened"
        :data="displayAndPaidSearchResultSidebarData"
        @close="toggleDisplayAndPaidSearchResultSidebar"
        @update="updateDisplayAndPaidSearchResultList"
      />

      <review-planning-sidebar
        :active="reviewPlanningSidebarOpened"
        :data="reviewPlanningSidebarData"
        :review-planning="reviewPlanning.items"
        :review-planning-id="reviewPlanning.id"
        @close="toggleReviewPlanningSidebar"
        @create="addToReviewPlanningItemsList"
        @update="updateReviewPlanningItemsList"
      />

      <review-result-sidebar
        :active="reviewResultSidebarOpened"
        :data="reviewResultSidebarData"
        :review-result="reviewResult.items"
        :review-result-id="reviewResult.id"
        @close="toggleReviewResultSidebar"
        @create="addToReviewResultItemsList"
        @update="updateReviewResultItemsList"
      />

      <crm-planning-sidebar
        :active="crmPlanningSidebarOpened"
        :data="crmPlanningSidebarData"
        @close="toggleCrmPlanningSidebar"
        @create="addToCrmPlanningList"
        @update="updateCrmPlanningList"
      />

      <crm-result-sidebar
        :active="crmResultSidebarOpened"
        :data="crmResultSidebarData"
        @close="toggleCrmResultSidebar"
        @update="updateCrmResultList"
      />

      <social-media-planning-sidebar
        :active="socialMediaPlanningSidebarOpened"
        :data="socialMediaPlanningSidebarData"
        @close="toggleSocialMediaPlanningSidebar"
        @create="addToSocialMediaPlanningList"
        @update="updateSocialMediaPlanningList"
      />

      <social-media-result-sidebar
        :active="socialMediaResultSidebarOpened"
        :data="socialMediaResultSidebarData"
        @close="toggleSocialMediaResultSidebar"
        @update="updateSocialMediaResultList"
      />

      <div v-if="isActiveTab(Tabs.Planning)">
        <vx-card class="mt-10">
          <vs-tabs>
            <!-- STATUS SEARCH -->
            <!-- <vs-tab label="Status Search" class="mt-5 p-0">
          <div class="flex flex-wrap-reverse items-center mt-10 mb-5">
            <button-create
              v-if="ableToEditCampaign(campaignUserId)"
              title="Add new"
              @click="openStatusSearchSidebar"
            />
          </div>

          <status-search-list
            :data="statusSearch"
            :user-id="campaignUserId"
            :on-edit="editStatusSearch"
            :on-delete="deleteStatusSearch"
          />
        </vs-tab> -->
            <!-- / STATUS SEARCH -->

            <!-- DISPLAY AND PAID SEARCH -->
            <vs-tab label="Display and Paid Search" class="mt-5 p-0">
              <vs-tabs>
                <vs-tab label="Planning" class="p-0">
                  <div class="flex flex-wrap-reverse items-center mt-10 mb-5">
                    <button-create
                      v-if="ableToEditCampaign(campaignUserId)"
                      title="Add new"
                      @click="openDisplayAndPaidSearchPlanningSidebar"
                    />
                  </div>

                  <display-and-paid-search-planning-card
                    v-for="item in displayAndPaidSearchPlanning"
                    :key="item.id"
                    :data="item"
                    :user-id="campaignUserId"
                    @edit="editDisplayAndPaidSearchPlanning"
                    @delete="deleteDisplayAndPaidSearchPlanning"
                    @refresh="fetchConfig"
                  />
                  <comment-product-campaign
                    field-name="paid_search_comment_planning"
                    :comment="comments.paid_search_comment_planning"
                    @save="saveComment"
                  />
                </vs-tab>

                <vs-tab label="Result" class="mt-10 p-0">
                  <display-and-paid-search-result-card
                    v-for="item in displayAndPaidSearchResult"
                    :key="item.id"
                    :data="item"
                    :user-id="campaignUserId"
                    @edit="editDisplayAndPaidSearchResult"
                  />
                  <comment-product-campaign
                    field-name="paid_search_comment_result"
                    :comment="comments.paid_search_comment_result"
                    @save="saveComment"
                  />
                </vs-tab>
              </vs-tabs>
            </vs-tab>
            <!-- / DISPLAY AND PAID SEARCH PLANNING -->

            <!-- REVIEW -->
            <vs-tab label="Review" class="mt-5 p-0">
              <vs-tabs>
                <vs-tab label="Planning" class="p-0">
                  <div class="flex flex-wrap-reverse items-center mt-10 mb-5">
                    <button-create
                      v-if="ableToEditCampaign(campaignUserId)"
                      title="Add new"
                      @click="openReviewPlanningSidebar"
                    />
                  </div>

                  <review-planning-tab
                    :data="reviewPlanning"
                    :user-id="campaignUserId"
                    :on-edit="editReviewPlanning"
                    :on-delete="deleteReviewPlanning"
                  />
                  <comment-product-campaign
                    field-name="review_comment_planning"
                    :comment="comments.review_comment_planning"
                    @save="saveComment"
                  />
                </vs-tab>

                <vs-tab label="Result" class="p-0">
                  <div class="flex flex-wrap-reverse items-center mt-10 mb-5">
                    <button-create
                      v-if="ableToEditCampaign(campaignUserId)"
                      title="Add new"
                      @click="openReviewResultSidebar"
                    />
                  </div>

                  <review-result-tab
                    :data="reviewResult"
                    :user-id="campaignUserId"
                    :on-edit="editReviewResult"
                    :on-delete="deleteReviewResult"
                  />
                  <comment-product-campaign
                    field-name="review_comment_result"
                    :comment="comments.review_comment_result"
                    @save="saveComment"
                  />
                </vs-tab>
              </vs-tabs>
            </vs-tab>
            <!-- / REVIEW -->

            <!-- CRM -->
            <vs-tab label="CRM" class="mt-5 p-0">
              <vs-tabs>
                <vs-tab label="Planning" class="p-0">
                  <div class="flex flex-wrap-reverse items-center mt-10 mb-5">
                    <button-create
                      v-if="ableToEditCampaign(campaignUserId)"
                      title="Add new"
                      @click="openCrmPlanningSidebar"
                    />
                  </div>

                  <crm-planning-card
                    v-for="item in crmPlanning"
                    :key="item.id"
                    :data="item"
                    :user-id="campaignUserId"
                    @delete="deleteCrmPlanning"
                    @edit="editCrmPlanning"
                    @refresh="fetchConfig"
                  />
                  <comment-product-campaign
                    field-name="crm_comment_planning"
                    :comment="comments.crm_comment_planning"
                    @save="saveComment"
                  />
                </vs-tab>

                <vs-tab label="Result" class="mt-10 p-0">
                  <crm-result-card
                    v-for="item in crmResult"
                    :key="item.id"
                    :data="item"
                    :user-id="campaignUserId"
                    @edit="editCrmResult"
                  />
                  <comment-product-campaign
                    field-name="crm_comment_result"
                    :comment="comments.crm_comment_result"
                    @save="saveComment"
                  />
                </vs-tab>
              </vs-tabs>
            </vs-tab>
            <!-- / CRM -->

            <!-- SOCIAL MEDIA -->
            <vs-tab label="Social Media" class="mt-5 p-0">
              <vs-tabs>
                <vs-tab label="Planning" class="p-0">
                  <div class="flex flex-wrap-reverse items-center mt-10 mb-5">
                    <button-create
                      v-if="ableToEditCampaign(campaignUserId)"
                      title="Add new"
                      @click="openSocialMediaPlanningSidebar"
                    />
                  </div>

                  <social-media-planning-card
                    v-for="item in socialMediaPlanning"
                    :key="item.id"
                    :data="item"
                    :user-id="campaignUserId"
                    @delete="deleteSocialMediaPlanning"
                    @edit="editSocialMediaPlanning"
                    @refresh="fetchConfig"
                  />
                  <comment-product-campaign
                    field-name="social_media_comment_planning"
                    :comment="comments.social_media_comment_planning"
                    @save="saveComment"
                  />
                </vs-tab>

                <vs-tab label="Result" class="mt-10 p-0">
                  <social-media-result-card
                    v-for="item in socialMediaResult"
                    :key="item.id"
                    :data="item"
                    :user-id="campaignUserId"
                    @edit="editSocialMediaResult"
                  />
                  <comment-product-campaign
                    field-name="social_media_comment_result"
                    :comment="comments.social_media_comment_result"
                    @save="saveComment"
                  />
                </vs-tab>
              </vs-tabs>
            </vs-tab>
            <!-- / SOCIAL MEDIA -->
          </vs-tabs>
        </vx-card>
      </div>

      <div v-else-if="isActiveTab(Tabs.Summary)">
        <summary-tab
          v-if="isActiveTab(Tabs.Summary)"
          class="mt-10"
          :user-id="campaignUserId"
        />
      </div>
    </div>
  </data-list-view-wrapper>
</template>

<script>
import { mapActions } from 'vuex';

import { CampaignAction } from '@/store/actionTypes';

import campaignRouteParamsMixin from '@/mixins/campaignRouteParamsMixin';
import manageCampaignMixin from '@/mixins/manageCampaignMixin';
import moduleCampaign from '@/store/campaigns/moduleCampaign';
import tabsMixin, { Tabs } from '@/mixins/tabsMixin';

import ButtonCreate from '@/views/components/buttons/ButtonCreate';
import CommentProductCampaign from '@/views/ui-elements/CommentProductCampaign.vue';
import CrmPlanningCard from '@/views/ui-elements/campaign-product/CrmPlanningCard';
import CrmPlanningSidebar from '@/views/sidebars/CrmPlanningSidebar';
import CrmResultCard from '@/views/ui-elements/campaign-product/CrmResultCard';
import CrmResultSidebar from '@/views/sidebars/CrmResultSidebar';
import DataListViewWrapper from '@/views/wrappers/DataListViewWrapper';
import DisplayAndPaidSearchPlanningCard from '@/views/ui-elements/campaign-product/DisplayAndPaidSearchPlanningCard';
import DisplayAndPaidSearchPlanningSidebar from '@/views/sidebars/DisplayAndPaidSearchPlanningSidebar';
import DisplayAndPaidSearchResultCard from '@/views/ui-elements/campaign-product/DisplayAndPaidSearchResultCard';
import DisplayAndPaidSearchResultSidebar from '@/views/sidebars/DisplayAndPaidSearchResultSidebar';
import ReviewPlanningSidebar from '@/views/sidebars/ReviewPlanningSidebar';
import ReviewPlanningTab from '@/views/ui-elements/campaign-product/ReviewPlanningTab';
import ReviewResultSidebar from '@/views/sidebars/ReviewResultSidebar';
import ReviewResultTab from '@/views/ui-elements/campaign-product/ReviewResultTab';
import SocialMediaPlanningCard from '@/views/ui-elements/campaign-product/SocialMediaPlanningCard';
import SocialMediaPlanningSidebar from '@/views/sidebars/SocialMediaPlanningSidebar';
import SocialMediaResultCard from '@/views/ui-elements/campaign-product/SocialMediaResultCard';
import SocialMediaResultSidebar from '@/views/sidebars/SocialMediaResultSidebar';
import StatusSearchSidebar from '@/views/sidebars/StatusSearchSidebar';
// import StatusSearchList from '@/views/ui-elements/campaign-product/StatusSearchList';
import SummaryTab from '@/views/ui-elements/campaign/SummaryTab';
import TabBtn from '@/views/components/tabs/TabButton';

export default {
  components: {
    ButtonCreate,
    CommentProductCampaign,
    DataListViewWrapper,
    DisplayAndPaidSearchPlanningCard,
    DisplayAndPaidSearchPlanningSidebar,
    DisplayAndPaidSearchResultCard,
    DisplayAndPaidSearchResultSidebar,
    CrmPlanningCard,
    CrmPlanningSidebar,
    CrmResultCard,
    CrmResultSidebar,
    ReviewPlanningSidebar,
    ReviewPlanningTab,
    ReviewResultSidebar,
    ReviewResultTab,
    SocialMediaPlanningCard,
    SocialMediaPlanningSidebar,
    SocialMediaResultCard,
    SocialMediaResultSidebar,
    // StatusSearchList,
    StatusSearchSidebar,
    SummaryTab,
    TabBtn,
  },
  mixins: [campaignRouteParamsMixin, manageCampaignMixin, tabsMixin],
  data() {
    return {
      activeTab: Tabs.Planning,
      campaign: {},
      comments: {
        paid_search_comment_planning: '',
        paid_search_comment_result: '',
        review_comment_planning: '',
        review_comment_result: '',
        crm_comment_planning: '',
        crm_comment_result: '',
        social_media_comment_planning: '',
        social_media_comment_result: '',
      },
      product: {},
      groupName: null,
      statusSearchSidebarOpened: false,
      statusSearchSidebarData: {},
      statusSearch: [],
      displayAndPaidSearchPlanningSidebarOpened: false,
      displayAndPaidSearchPlanningSidebarData: {},
      displayAndPaidSearchPlanning: [],
      displayAndPaidSearchResultSidebarOpened: false,
      displayAndPaidSearchResultSidebarData: {},
      displayAndPaidSearchResult: [],
      reviewPlanningSidebarOpened: false,
      reviewPlanningSidebarData: {},
      reviewPlanning: {},
      reviewResultSidebarOpened: false,
      reviewResultSidebarData: {},
      reviewResult: {},
      crmPlanningSidebarOpened: false,
      crmPlanningSidebarData: {},
      crmPlanning: [],
      crmResultSidebarOpened: false,
      crmResultSidebarData: {},
      crmResult: [],
      socialMediaPlanningSidebarOpened: false,
      socialMediaPlanningSidebarData: {},
      socialMediaPlanning: [],
      socialMediaResultSidebarOpened: false,
      socialMediaResultSidebarData: {},
      socialMediaResult: [],
    };
  },
  computed: {
    campaignName() {
      return this.campaign.name;
    },
    campaignUserId() {
      return this.campaign.user_id;
    },
  },
  methods: {
    ...mapActions('campaign', [
      CampaignAction.fetchProductConfig,
      CampaignAction.fetchProductGroupConfig,
      CampaignAction.fetchDisplayAndPaidSearchResult,
      CampaignAction.fetchCrmResult,
      CampaignAction.fetchSocialMediaResult,
      CampaignAction.updateProductComment,
      CampaignAction.removeStatusSearch,
      CampaignAction.removeDisplayAndPaidSearchPlanning,
      CampaignAction.removeCrmPlanning,
      CampaignAction.removeSocialMediaPlanning,
      CampaignAction.removeReviewPlanningItem,
      CampaignAction.removeReviewResultItem,
    ]),

    registerStoreModules() {
      if (!moduleCampaign.isRegistered) {
        this.$store.registerModule('campaign', moduleCampaign);
        moduleCampaign.isRegistered = true;
      }
    },
    // Open
    openStatusSearchSidebar() {
      this.statusSearchSidebarData = {};
      this.toggleStatusSearchSidebar(true);
    },
    openDisplayAndPaidSearchPlanningSidebar() {
      this.displayAndPaidSearchPlanningSidebarData = {};
      this.toggleDisplayAndPaidSearchPlanningSidebar(true);
    },
    openDisplayAndPaidSearchResultSidebar() {
      this.displayAndPaidSearchResultSidebarData = {};
      this.toggleDisplayAndPaidSearchResultSidebar(true);
    },
    openCrmPlanningSidebar() {
      this.crmPlanningSidebarData = {};
      this.toggleCrmPlanningSidebar(true);
    },
    openCrmResultSidebar() {
      this.crmResultSidebarData = {};
      this.toggleCrmResultSidebar(true);
    },
    openSocialMediaPlanningSidebar() {
      this.socialMediaPlanningSidebarData = {};
      this.toggleSocialMediaPlanningSidebar(true);
    },
    openSocialMediaResultSidebar() {
      this.socialMediaResultSidebarData = {};
      this.toggleSocialMediaResultSidebar(true);
    },
    openReviewPlanningSidebar() {
      this.reviewPlanningSidebarData = {};
      this.toggleReviewPlanningSidebar(true);
    },
    openReviewResultSidebar() {
      this.reviewResultSidebarData = {};
      this.toggleReviewResultSidebar(true);
    },
    // Edit
    editStatusSearch(item) {
      this.statusSearchSidebarData = item;
      this.toggleStatusSearchSidebar(true);
    },
    editDisplayAndPaidSearchPlanning(item) {
      this.displayAndPaidSearchPlanningSidebarData = item;
      this.toggleDisplayAndPaidSearchPlanningSidebar(true);
    },
    editDisplayAndPaidSearchResult(item) {
      this.displayAndPaidSearchResultSidebarData = item;
      this.toggleDisplayAndPaidSearchResultSidebar(true);
    },
    editCrmPlanning(item) {
      this.crmPlanningSidebarData = item;
      this.toggleCrmPlanningSidebar(true);
    },
    editCrmResult(item) {
      this.crmResultSidebarData = item;
      this.toggleCrmResultSidebar(true);
    },
    editSocialMediaPlanning(item) {
      this.socialMediaPlanningSidebarData = item;
      this.toggleSocialMediaPlanningSidebar(true);
    },
    editSocialMediaResult(item) {
      this.socialMediaResultSidebarData = item;
      this.toggleSocialMediaResultSidebar(true);
    },
    editReviewPlanning(item) {
      this.reviewPlanningSidebarData = item;
      this.toggleReviewPlanningSidebar(true);
    },
    editReviewResult(item) {
      this.reviewResultSidebarData = item;
      this.toggleReviewResultSidebar(true);
    },
    // Add
    addToStatusSearchList(item) {
      this.statusSearch.push(item);
    },
    addToDisplayAndPaidSearchPlanningList(item) {
      this.displayAndPaidSearchPlanning.push(item);
      this.refetchDisplayAndPaidSearchResults();
    },
    addToCrmPlanningList(item) {
      this.crmPlanning.push(item);
      this.refetchCrmResults();
    },
    addToSocialMediaPlanningList(item) {
      this.socialMediaPlanning.push(item);
      this.refetchSocialMediaResults();
    },
    async addToReviewPlanningItemsList(item) {
      this.reviewPlanning.items.push(item);

      await this.fetchConfig();
    },
    async addToReviewResultItemsList(item) {
      this.reviewResult.items.push(item);

      await this.fetchConfig();
    },
    // Update
    updateStatusSearchList(item) {
      const index = this.statusSearch.findIndex((el) => el.id === item.id);
      this.$set(this.statusSearch, index, item);
    },
    updateDisplayAndPaidSearchPlanningList(item) {
      const index = this.displayAndPaidSearchPlanning.findIndex(
        (el) => el.id === item.id
      );

      this.$set(this.displayAndPaidSearchPlanning, index, item);
      this.refetchDisplayAndPaidSearchResults();
    },
    updateDisplayAndPaidSearchResultList(item) {
      const index = this.displayAndPaidSearchResult.findIndex(
        (el) => el.id === item.id
      );

      this.$set(this.displayAndPaidSearchResult, index, item);
    },
    updateCrmPlanningList(item) {
      const index = this.crmPlanning.findIndex((el) => el.id === item.id);

      this.$set(this.crmPlanning, index, item);
      this.refetchCrmResults();
    },
    updateCrmResultList(item) {
      const index = this.crmResult.findIndex((el) => el.id === item.id);

      this.$set(this.crmResult, index, item);
    },
    updateSocialMediaPlanningList(item) {
      const index = this.socialMediaPlanning.findIndex(
        (el) => el.id === item.id
      );

      this.$set(this.socialMediaPlanning, index, item);
      this.refetchSocialMediaResults();
    },
    updateSocialMediaResultList(item) {
      const index = this.socialMediaResult.findIndex((el) => el.id === item.id);

      this.$set(this.socialMediaResult, index, item);
    },
    async updateReviewPlanningItemsList(item) {
      const index = this.reviewPlanning.items.findIndex(
        (el) => el.id === item.id
      );
      this.$set(this.reviewPlanning.items, index, item);

      await this.fetchConfig();
    },
    async updateReviewResultItemsList(item) {
      const index = this.reviewResult.items.findIndex(
        (el) => el.id === item.id
      );
      this.$set(this.reviewResult.items, index, item);

      await this.fetchConfig();
    },
    async deleteStatusSearch(id) {
      try {
        await this.removeStatusSearch({
          id,
          campaignProductId: this.campaignProductId,
        });

        this.statusSearch = this.statusSearch.filter((item) => item.id !== id);

        this.notifySuccess(
          'Status Search delete',
          'The status search has been successfully deleted.'
        );
      } catch (error) {
        this.notifyError(error, 'Problem with deleting a status search.');
      }
    },
    async deleteDisplayAndPaidSearchPlanning(id) {
      try {
        await this.removeDisplayAndPaidSearchPlanning(id);

        this.displayAndPaidSearchPlanning =
          this.displayAndPaidSearchPlanning.filter((item) => item.id !== id);

        // Delete matched result
        this.displayAndPaidSearchResult =
          this.displayAndPaidSearchResult.filter(
            (item) => item.dps_planning_id !== id
          );
      } catch (error) {
        this.notifyError(
          error,
          'Problem with deleting a display and paid search planning.'
        );
      }
    },
    async deleteCrmPlanning(id) {
      try {
        await this.removeCrmPlanning(id);

        this.crmPlanning = this.crmPlanning.filter((item) => item.id !== id);

        // Delete matched result
        this.crmResult = this.crmResult.filter(
          (item) => item.crm_planning_id !== id
        );
      } catch (error) {
        this.notifyError(error, 'Problem with deleting a crm planning.');
      }
    },
    async deleteSocialMediaPlanning(id) {
      try {
        await this.removeSocialMediaPlanning(id);

        this.socialMediaPlanning = this.socialMediaPlanning.filter(
          (item) => item.id !== id
        );

        // Delete matched result
        this.socialMediaResult = this.socialMediaResult.filter(
          (item) => item.sm_planning_id !== id
        );
      } catch (error) {
        this.notifyError(
          error,
          'Problem with deleting a social media planning.'
        );
      }
    },
    async deleteReviewPlanning(id) {
      try {
        await this.removeReviewPlanningItem(id);

        this.reviewPlanning.items = this.reviewPlanning.items.filter(
          (item) => item.id !== id
        );

        this.notifySuccess(
          'Review Planning item deleted',
          'The review planning item has been successfully deleted.'
        );
      } catch (error) {
        this.notifyError(
          error,
          'Problem with deleting a review planning item.'
        );
      }

      await this.fetchConfig();
    },
    async deleteReviewResult(id) {
      try {
        await this.removeReviewResultItem(id);

        this.reviewResult.items = this.reviewResult.items.filter(
          (item) => item.id !== id
        );

        this.notifySuccess(
          'Review Result item deleted',
          'The review result item has been successfully deleted.'
        );
      } catch (error) {
        this.notifyError(error, 'Problem with deleting a review result item.');
      }

      await this.fetchConfig();
    },
    async saveComment(comment, fieldName) {
      try {
        this.$set(this.comments, fieldName, comment);

        await this.updateProductComment({
          id: this.campaignProductId,
          ...this.comments,
        });

        this.notifySuccess(
          'Comment updated',
          'The comment has been successfully updated.'
        );
      } catch (error) {
        this.notifyError(error, 'Problem with comments update.');
      }
    },
    async refetchDisplayAndPaidSearchResults() {
      try {
        this.displayAndPaidSearchResult =
          await this.fetchDisplayAndPaidSearchResult({
            campaign_product_id: this.campaignProductId,
            campaign_product_group_id: this.campaignProductGroupId,
          });
      } catch (error) {
        this.notifyError(
          error,
          'Problem with loading display and paid search results.'
        );
      }
    },
    async refetchCrmResults() {
      try {
        this.crmResult = await this.fetchCrmResult({
          campaign_product_id: this.campaignProductId,
          campaign_product_group_id: this.campaignProductGroupId,
        });
      } catch (error) {
        this.notifyError(error, 'Problem with loading crm results.');
      }
    },
    async refetchSocialMediaResults() {
      try {
        this.socialMediaResult = await this.fetchSocialMediaResult({
          campaign_product_id: this.campaignProductId,
          campaign_product_group_id: this.campaignProductGroupId,
        });
      } catch (error) {
        this.notifyError(error, 'Problem with loading social media results.');
      }
    },
    setData(data) {
      const {
        campaign,
        comments,
        product = {},
        groupName = null,
        statusSearch,
        displayPaidSearchPlanning,
        displayPaidSearchResult,
        reviewPlanning,
        reviewResult,
        crmPlanning,
        crmResult,
        socialMediaPlanning,
        socialMediaResult,
      } = data;

      this.campaign = campaign;
      this.comments = comments;
      this.product = product;
      this.groupName = groupName;
      this.statusSearch = statusSearch;
      this.displayAndPaidSearchPlanning = displayPaidSearchPlanning;
      this.displayAndPaidSearchResult = displayPaidSearchResult;
      this.reviewPlanning = reviewPlanning;
      this.reviewResult = reviewResult;
      this.crmPlanning = crmPlanning;
      this.crmResult = crmResult;
      this.socialMediaPlanning = socialMediaPlanning;
      this.socialMediaResult = socialMediaResult;
    },
    // Toggle
    toggleStatusSearchSidebar(val = false) {
      this.statusSearchSidebarOpened = val;
    },
    toggleDisplayAndPaidSearchPlanningSidebar(val = false) {
      this.displayAndPaidSearchPlanningSidebarOpened = val;
    },
    toggleDisplayAndPaidSearchResultSidebar(val = false) {
      this.displayAndPaidSearchResultSidebarOpened = val;
    },
    toggleCrmPlanningSidebar(val = false) {
      this.crmPlanningSidebarOpened = val;
    },
    toggleCrmResultSidebar(val = false) {
      this.crmResultSidebarOpened = val;
    },
    toggleSocialMediaPlanningSidebar(val = false) {
      this.socialMediaPlanningSidebarOpened = val;
    },
    toggleSocialMediaResultSidebar(val = false) {
      this.socialMediaResultSidebarOpened = val;
    },
    toggleReviewPlanningSidebar(val = false) {
      this.reviewPlanningSidebarOpened = val;
    },
    toggleReviewResultSidebar(val = false) {
      this.reviewResultSidebarOpened = val;
    },
    async fetchConfig() {
      try {
        const config = this.hasProductId
          ? await this.fetchProductConfig(this.campaignProductId)
          : await this.fetchProductGroupConfig(this.campaignProductGroupId);
        this.setData(config);
      } catch (error) {
        console.error(error);
      }
    },
  },
  async created() {
    this.registerStoreModules();

    await this.fetchConfig();
  },
};
</script>

<style lang="scss" scoped>
.heading {
  font-size: 14px;
}

div,
p,
span {
  font-size: 12px;
}

.con-vs-tabs {
  overflow: initial;
}
</style>
