import { render, staticRenderFns } from "./CampaignProduct.vue?vue&type=template&id=32f7e461&scoped=true&"
import script from "./CampaignProduct.vue?vue&type=script&lang=js&"
export * from "./CampaignProduct.vue?vue&type=script&lang=js&"
import style0 from "./CampaignProduct.vue?vue&type=style&index=0&id=32f7e461&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32f7e461",
  null
  
)

export default component.exports