<template>
  <vs-sidebar
    v-model="isActive"
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="vs-sidebar__wrapper"
    spacer
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ titleType }} PLANNING</h4>
      <feather-icon
        icon="XIcon"
        class="cursor-pointer"
        @click.stop="isActive = false"
      />
    </div>

    <vs-divider class="mb-0" />

    <component
      :is="scrollbarTag"
      :settings="settings"
      class="vs-sidebar__inner"
    >
      <!-- CHANNEL -->
      <div>
        <vs-label text="Channel*" />
        <vs-input
          v-model="dataChannel"
          v-validate="'required'"
          class="w-full"
          name="channel"
        />
        <v-error :err="errors" field="channel" />
      </div>

      <!-- TYPE -->
      <div class="mt-4">
        <vs-label text="Type" />
        <v-select
          v-model="dataType"
          :options="planningAndResultTypeList"
          :clearable="false"
        />
      </div>

      <!-- BUDGET -->
      <div class="mt-4">
        <vs-label text="Budget ($)" />
        <vs-input
          v-model="dataBudget"
          v-validate="'required'"
          class="w-full"
          name="budget"
          type="number"
          step="0.01"
        />
        <v-error :err="errors" field="budget" />
      </div>

      <!-- START DATE -->
      <div class="mt-4">
        <vs-label text="Start date" />
        <datepicker
          v-model="dataStartDate"
          placeholder="Select Date"
          class="w-full"
        />
      </div>

      <!-- END DATE -->
      <div class="mt-4">
        <vs-label text="End date" />
        <datepicker
          v-model="dataEndDate"
          placeholder="Select Date"
          class="w-full"
        />
      </div>

      <!-- IMPRESSIONS -->
      <div v-if="impressionsVisible" class="mt-4">
        <vs-label text="Impressions" />
        <vs-input
          v-model="dataImpressions"
          v-validate="'required'"
          class="w-full"
          name="impressions"
          type="number"
          step="1"
        />
        <v-error :err="errors" field="impressions" />
      </div>

      <!-- CTR -->
      <div class="mt-4">
        <vs-label text="CTR (%)" />
        <vs-input
          v-model="dataCtr"
          v-validate="'required'"
          class="w-full"
          name="ctr"
          type="number"
          step="0.01"
        />
        <v-error :err="errors" field="ctr" />
      </div>

      <!-- CPC -->
      <div class="mt-4">
        <vs-label text="CPC ($)" />
        <vs-input
          v-model="dataCpc"
          v-validate="'required'"
          class="w-full"
          name="cpc"
          type="number"
          step="0.01"
        />
        <v-error :err="errors" field="cpc" />
      </div>

      <!-- CR -->
      <div class="mt-4">
        <vs-label text="CR (%)" />
        <vs-input
          v-model="dataCr"
          v-validate="'required'"
          class="w-full"
          name="cr"
          type="number"
          step="0.01"
        />
        <v-error :err="errors" field="cr" />
      </div>

      <!-- PRICE PER UNIT -->
      <div class="mt-4">
        <vs-label text="Price per unit ($)" />
        <vs-input
          v-model="dataPricePerUnit"
          v-validate="'required'"
          class="w-full"
          name="price_per_unit"
          type="number"
          step="0.01"
        />
        <v-error :err="errors" field="price_per_unit" />
      </div>

      <!-- BUTTONS -->
      <sidebar-buttons
        :valid="isFormValid"
        @click="handleSubmit"
        @cancel="handleClose"
      />
    </component>
  </vs-sidebar>
</template>

<script>
import { mapActions } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select';

import { CampaignAction } from '@/store/actionTypes';
import {
  getPlanningAndResultTypeByName,
  PlanningAndResultTypeEnum,
  planningAndResultTypeList,
} from '@/enums/PlanningAndResultTypeEnum';

import campaignRouteParamsMixin from '@/mixins/campaignRouteParamsMixin';
import sidebarMixin from '@/mixins/sidebarMixin';

import vError from '@/components/VError';

export default {
  components: {
    Datepicker,
    vError,
    vSelect,
  },
  mixins: [campaignRouteParamsMixin, sidebarMixin],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    statusSearch: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dataId: null,
      dataChannel: '',
      dataType: PlanningAndResultTypeEnum.PPC,
      dataBudget: 0,
      dataStartDate: new Date(),
      dataEndDate: new Date(),
      dataImpressions: 0,
      dataCtr: 0,
      dataCpc: 0,
      dataCr: 0,
      dataPricePerUnit: 0,
      planningAndResultTypeList,
      impressionsVisible: false,
    };
  },
  computed: {
    campaignProductId() {
      return +this.$route.params.productId;
    },
    isFormValid() {
      return !this.errors.any() && this.dataChannel && this.dataType;
    },
  },
  watch: {
    active(val) {
      if (!val) {
        return;
      }

      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        const {
          id,
          channel,
          type,
          budget,
          start_date,
          end_date,
          impressions,
          ctr,
          cpc,
          cr,
          price_per_unit,
        } = this.data;

        this.dataId = id;
        this.dataChannel = channel;
        this.dataType = getPlanningAndResultTypeByName(type);
        this.dataBudget = budget;
        this.dataStartDate = start_date;
        this.dataEndDate = end_date;
        this.dataImpressions = impressions;
        this.dataCtr = ctr * 100;
        this.dataCpc = cpc;
        this.dataCr = cr * 100;
        this.dataPricePerUnit = price_per_unit;

        this.initValues();
      }
    },
    dataType(val) {
      this.impressionsVisible =
        val.label !== PlanningAndResultTypeEnum.PPC.label;
    },
  },
  methods: {
    ...mapActions('campaign', [
      CampaignAction.addCrmPlanning,
      CampaignAction.updateCrmPlanning,
    ]),

    initValues() {
      if (this.data.id) {
        return;
      }

      this.dataId = null;
      this.dataChannel = '';
      this.dataType = PlanningAndResultTypeEnum.PPC;
      this.dataBudget = 0;
      this.dataStartDate = new Date();
      this.dataEndDate = new Date();
      this.dataImpressions = 0;
      this.dataCtr = 0;
      this.dataCpc = 0;
      this.dataCr = 0;
      this.dataPricePerUnit = 0;
    },
    async handleSubmit() {
      if (!(await this.$validator.validateAll())) {
        return;
      }

      const obj = this.addProperIdToSubmitObject({
        id: this.dataId,
        channel: this.dataChannel,
        type: this.dataType.value,
        budget: this.dataBudget,
        start_date: this.dataStartDate,
        end_date: this.dataEndDate,
        impressions: this.dataImpressions,
        ctr: this.dataCtr,
        cpc: this.dataCpc,
        cr: this.dataCr,
        price_per_unit: this.dataPricePerUnit,
      });

      this.$vs.loading();

      if (this.shouldUpdate) {
        try {
          const crm = await this.updateCrmPlanning(obj);
          this.$emit('update', crm);

          this.notifySuccess(
            'CRM Planning updated',
            'The CRM Planning has been successfully updated.'
          );
        } catch (error) {
          this.notifyError(error, 'Problem with updating a crm planning.');
        }
      } else {
        delete obj.id;

        try {
          const crm = await this.addCrmPlanning(obj);
          this.$emit('create', crm);

          this.notifySuccess(
            'CRM Planning added',
            'The CRM Planning has been successfully added.'
          );
        } catch (error) {
          this.notifyError(error, 'Problem with adding a crm planning.');
        }
      }

      this.$vs.loading.close();
      this.$emit('close');
      this.initValues();
    },
  },
};
</script>
