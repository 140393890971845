import { mapActions } from 'vuex';

import { CampaignAction } from '@/store/actionTypes';
import { PlanningAndResultTabList } from '@/enums/PlanningAndResultTabEnum';

export default {
  data() {
    return {
      activeMovePrompt: false,
      targetTab: null,
    };
  },
  computed: {
    tabOptions() {
      return PlanningAndResultTabList.filter(
        (tab) => tab.value !== this.tabName
      );
    },
  },
  methods: {
    ...mapActions('campaign', [CampaignAction.changeProductConfigTab]),

    openMoveConfirmation() {
      this.activeMovePrompt = true;
    },
    closeMoveConfirmation() {
      this.activeMovePrompt = false;
      this.targetTab = null;
    },
    async showSelected() {
      const dataToSend = {
        ...this.data,
        targetTab: this.targetTab.value,
        currentTab: this.tabName,
      };

      await this.changeProductConfigTab(dataToSend);
      this.handleRefresh();
    },
  },
};
