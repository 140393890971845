<template>
  <vx-card>
    <div
      class="
        vx-row
        flex-col-reverse
        md:flex-col-reverse
        sm:flex-row
        lg:flex-row
      "
    >
      <div class="vx-col w-full flex flex-col">
        <h4 class="text-left mb-8">Comment</h4>
        <vs-textarea :value="currentComment" name="comment" @input="onInput" />
        <div>
          <vs-button @click="$emit('save', currentComment, fieldName)">
            Save comment
          </vs-button>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
export default {
  props: {
    comment: {
      type: String,
      default: '',
      require: true,
    },
    fieldName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentComment: this.comment,
    };
  },
  watch: {
    comment() {
      this.currentComment = this.comment;
    },
  },
  methods: {
    onInput(value) {
      this.currentComment = value;
    },
  },
};
</script>
