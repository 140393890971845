<template>
  <vx-card
    :title="data.channel_name"
    title-color="#fff"
    card-background="primary"
    content-color="#fff"
    class="mb-4"
  >
    <feather-icon
      v-if="ableToEditCampaign(userId)"
      icon="EditIcon"
      svg-classes="h-5 w-5"
      class="edit-icon"
      @click="handleEdit"
    />
    <div class="vx-row">
      <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
        <p class="vs-input--label pl-0 text-white"><b>Spent:</b></p>
        <p>{{ currency(data.spent) }}</p>
        <p class="vs-input--label pl-0 text-white mt-2"><b>Impressions:</b></p>
        <p>{{ data.impressions }}</p>
        <p class="vs-input--label pl-0 text-white mt-2"><b>Clicks:</b></p>
        <p>{{ data.clicks }}</p>
        <p class="vs-input--label pl-0 text-white mt-2"><b>Engagement:</b></p>
        <p>Comments: {{ data.comments }}</p>
        <p>Shares: {{ data.shares }}</p>
        <p>Likes: {{ data.likes }}</p>
      </div>
      <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
        <p class="vs-input--label pl-0 text-white mt-2"><b>CTR:</b></p>
        <p>{{ data.ctr | percentage }}</p>
        <p class="vs-input--label pl-0 text-white mt-2"><b>CPC:</b></p>
        <p>{{ currency(data.cpc) }}</p>
        <p class="vs-input--label pl-0 text-white mt-2"><b>CR:</b></p>
        <p>{{ data.cr | percentage }}</p>
      </div>
      <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
        <p class="vs-input--label pl-0 text-white mt-2"><b>Units sold:</b></p>
        <p>{{ data.units_sold }}</p>
        <p class="vs-input--label pl-0 text-white mt-2">
          <b>Units sold diff:</b>
        </p>
        <p>{{ data.units_sold_diff_percent | percentage }}</p>
        <p class="vs-input--label pl-0 text-white mt-2">
          <b>Price per unit:</b>
        </p>
        <p>{{ currency(data.price_per_unit) }}</p>
        <p class="vs-input--label pl-0 text-white mt-2"><b>CPA:</b></p>
        <p>{{ currency(data.cpa) }}</p>
      </div>
      <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
        <p class="vs-input--label pl-0 text-white"><b>Revenue:</b></p>
        <p>{{ currency(data.revenue) }}</p>
        <p class="vs-input--label pl-0 text-white"><b>Revenue diff:</b></p>
        <p>{{ data.revenue_diff_percent | percentage }}</p>
        <p class="vs-input--label pl-0 text-white mt-2"><b>ROAS:</b></p>
        <p>{{ data.roa | percentage }}</p>
      </div>
    </div>
  </vx-card>
</template>

<script>
import manageCampaignMixin from '@/mixins/manageCampaignMixin';
import useCurrencyMixin from '@/mixins/useCurrencyMixin';

export default {
  mixins: [manageCampaignMixin, useCurrencyMixin],
  props: {
    data: {
      type: Object,
      required: true,
    },
    userId: {
      type: [Number, null],
      default: null,
    },
  },
  methods: {
    handleEdit() {
      this.$emit('edit', this.data);
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-icon {
  position: absolute !important;
  cursor: pointer;
  top: -23px;
  right: 20px;
}
</style>
