<template>
  <div class="flex flex-col">
    <div class="data-list-container">
      <vs-table ref="table" :data="data.items">
        <template slot="thead">
          <vs-th sort-key="spent">Spent</vs-th>
          <vs-th sort-key="measure_date">Measure Date</vs-th>
          <vs-th sort-key="sessions_on_pdp_since_start_day">
            Sessions on pdp since start day
          </vs-th>
          <vs-th sort-key="sold_since_start_day">Sold since start day</vs-th>
          <vs-th sort-key="product_price">Product price</vs-th>
          <vs-th sort-key="reviews">Reviews</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr v-for="(tr, indextr) in data" :key="indextr" :data="tr">
              <vs-td>
                <p>{{ currency(tr.spent) }}</p>
              </vs-td>

              <vs-td>
                <p>{{ tr.measure_date }}</p>
              </vs-td>

              <vs-td>
                <p>{{ tr.sessions_on_pdp_since_start_day }}</p>
              </vs-td>

              <vs-td>
                <p>{{ tr.sold_since_start_day }}</p>
              </vs-td>

              <vs-td>
                <p>{{ currency(tr.product_price) }}</p>
              </vs-td>

              <vs-td>
                <p>{{ tr.reviews }}</p>
              </vs-td>

              <vs-td width="120" class="whitespace-no-wrap">
                <feather-icon
                  v-if="ableToEditCampaign(userId)"
                  icon="EditIcon"
                  svg-classes="w-5 h-5 hover:text-primary stroke-current"
                  @click.stop="onEdit(tr)"
                />
                <feather-icon
                  v-if="ableToEditCampaign(userId)"
                  icon="TrashIcon"
                  svg-classes="w-5 h-5 hover:text-danger stroke-current"
                  class="ml-2"
                  @click.stop="confirmDelete(tr.id, tr.id)"
                />
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>
    </div>

    <vx-card card-background="primary" content-color="#fff" class="mt-8 pt-5">
      <div class="vx-row">
        <div class="vx-col md:w-1/4 w-1/2 lg:mb-10 mb-6">
          Reviews diff
          <h3 class="text-white">{{ data.reviews_diff }}</h3>
        </div>
        <div class="vx-col md:w-1/4 w-1/2 lg:mb-10 mb-6">
          Reviews diff
          <h3 class="text-white">
            {{ data.reviews_diff_percent | percentage }}
          </h3>
        </div>

        <div class="vx-col md:w-1/4 w-1/2 lg:mb-10 mb-6"></div>
        <div class="vx-col md:w-1/4 w-1/2 lg:mb-10 mb-6"></div>

        <div class="vx-col md:w-1/4 w-1/2 lg:mb-10 mb-6">
          Cost per review
          <h3 class="text-white">{{ currency(data.cost_per_review) }}</h3>
        </div>
        <div class="vx-col md:w-1/4 w-1/2 lg:mb-10 mb-6">
          Cost per review diff
          <h3 class="text-white">{{ currency(data.cost_per_review_diff) }}</h3>
        </div>
        <div class="vx-col md:w-1/4 w-1/2 lg:mb-10 mb-6">
          Cost per review diff
          <h3 class="text-white">
            {{ data.cost_per_review_diff_percent | percentage }}
          </h3>
        </div>
        <div class="vx-col md:w-1/4 w-1/2 lg:mb-10 mb-6">
          Conversion rate
          <h3 class="text-white">{{ data.conversion_rate | percentage }}</h3>
        </div>

        <div class="vx-col md:w-1/4 w-1/2 lg:mb-10 mb-6">
          Revenue
          <h3 class="text-white">{{ currency(data.revenue) }}</h3>
        </div>
        <div class="vx-col md:w-1/4 w-1/2 lg:mb-10 mb-6">
          Uplift
          <h3 class="text-white">{{ data.uplift | numInt }}</h3>
        </div>
        <div class="vx-col md:w-1/4 w-1/2 lg:mb-10 mb-6">
          Uplift revenue
          <h3 class="text-white">{{ currency(data.uplift_revenue) }}</h3>
        </div>
        <div class="vx-col md:w-1/4 w-1/2 lg:mb-10 mb-6">
          ROR
          <h3 class="text-white">{{ data.ror | percentage }}</h3>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import manageCampaignMixin from '@/mixins/manageCampaignMixin';
import useCurrencyMixin from '@/mixins/useCurrencyMixin';

export default {
  mixins: [manageCampaignMixin, useCurrencyMixin],
  props: {
    data: {
      type: Object,
      required: true,
    },
    onEdit: {
      type: Function,
      required: true,
    },
    onDelete: {
      type: Function,
      required: true,
    },
    userId: {
      type: [Number, null],
      default: null,
    },
  },
  methods: {
    confirmDelete(id, val) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Are you sure you want to delete ID "${val}"?`,
        accept: () => this.onDelete(id),
        acceptText: 'Delete',
      });
    },
  },
};
</script>
