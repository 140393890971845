export const PlanningAndResulTabEnum = Object.freeze({
  DISPLAY_AND_SEARCH: {
    label: 'Display And Search',
    value: 'display_and_search'
  },
  CRM: {
    label: 'CRM',
    value: 'crm',
  },
  SOCIAL_MEDIA: {
    label: 'Social Media',
    value: 'social_media'
  },
})

export const PlanningAndResultTabList = [...Object.values(PlanningAndResulTabEnum)];
