export const ReviewTypeEnum = Object.freeze({
  NONE: { label: '-', value: null },
  CONSUMER: { label: 'Consumer', value: 'consumer' },
  MEDIA: { label: 'Media', value: 'media' },
});

export const reviewTypeList = [...Object.values(ReviewTypeEnum)];

export const getReviewByValue = (val = null) => {
  if (val === null) return '';

  return reviewTypeList.find((el) => el.value === val);
};
