<template>
  <vs-sidebar
    v-model="isActive"
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="vs-sidebar__wrapper"
    spacer
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ titleType }} PLANNING</h4>
      <feather-icon
        icon="XIcon"
        class="cursor-pointer"
        @click.stop="isActive = false"
      />
    </div>

    <vs-divider class="mb-0" />

    <component
      :is="scrollbarTag"
      :settings="settings"
      class="vs-sidebar__inner"
    >
      <!-- TYPE OF REVIEW -->
      <div>
        <vs-label text="Type of review" />
        <v-select
          v-model="dataTypeOfReview"
          :options="reviewTypeList"
          :clearable="false"
          class="mb-4 md:mb-0"
        />
      </div>

      <!-- ACTIVITY -->
      <div class="mt-4">
        <vs-label text="Activity" />
        <vs-input
          v-model="dataActivity"
          v-validate="'required'"
          class="w-full"
          name="activity"
        />
        <v-error :err="errors" field="activity" />
      </div>

      <!-- BUDGET -->
      <div class="mt-4">
        <vs-label text="Budget" />
        <vs-input
          v-model="dataBudget"
          v-validate="'required'"
          type="number"
          step="0.01"
          min="0"
          class="w-full"
          name="budget"
        />
        <v-error :err="errors" field="budget" />
      </div>

      <!-- START DATE -->
      <div class="mt-4">
        <vs-label text="Start date" />
        <datepicker
          v-model="dataStartDate"
          placeholder="Select Date"
          class="w-full"
        />
      </div>

      <!-- END DATE -->
      <div class="mt-4">
        <vs-label text="End date" />
        <datepicker
          v-model="dataEndDate"
          placeholder="Select Date"
          class="w-full"
        />
      </div>

      <!-- Reviews Start -->
      <div class="mt-4">
        <vs-label text="Reviews Start" />
        <vs-input
          v-model="dataReviewsStart"
          v-validate="'required'"
          type="number"
          step="1"
          min="0"
          class="w-full"
          name="reviews_start"
        />
        <v-error :err="errors" field="reviews_start" />
      </div>

      <!-- Reviews Target -->
      <div class="mt-4">
        <vs-label text="Reviews Target" />
        <vs-input
          v-model="dataReviewsTarget"
          v-validate="'required'"
          type="number"
          step="1"
          min="0"
          class="w-full"
          name="reviews_target"
        />
        <v-error :err="errors" field="reviews_target" />
      </div>

      <!-- SESSIONS ON PDP LAST 30 DAYS -->
      <div class="mt-4">
        <vs-label text="Sessions on pdp last 30 days" />
        <vs-input
          v-model="dataSessionsOnPdpLast30Days"
          v-validate="'required'"
          type="number"
          step="1"
          min="0"
          class="w-full"
          name="sessions_on_pdp_last_30_days"
        />
        <v-error :err="errors" field="sessions_on_pdp_last_30_days" />
      </div>

      <!-- SOLD LAST 30 DAYS -->
      <div class="mt-4">
        <vs-label text="Sold last 30 days" />
        <vs-input
          v-model="dataSoldLast30Days"
          v-validate="'required'"
          type="number"
          step="1"
          min="0"
          class="w-full"
          name="sold_last_30_days"
        />
        <v-error :err="errors" field="sold_last_30_days" />
      </div>

      <!-- PRODUCT PRICE -->
      <div class="mt-4">
        <vs-label text="Product price" />
        <vs-input
          v-model="dataProductPrice"
          v-validate="'required'"
          type="number"
          step="0.01"
          min="0"
          class="w-full"
          name="product_price"
        />
        <v-error :err="errors" field="product_price" />
      </div>

      <!-- UPLIFT CR -->
      <div class="mt-4">
        <vs-label text="Uplift CR (%)" />
        <vs-input
          v-model="dataUpliftCr"
          v-validate="'required'"
          type="number"
          step="0.01"
          min="0"
          class="w-full"
          name="uplift_cr"
        />
        <v-error :err="errors" field="uplift_cr" />
      </div>

      <!-- BUTTONS -->
      <sidebar-buttons
        :valid="isFormValid"
        @click="handleSubmit"
        @cancel="handleClose"
      />
    </component>
  </vs-sidebar>
</template>

<script>
import { mapActions } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select';

import { CampaignAction } from '@/store/actionTypes';
import { getReviewByValue, reviewTypeList } from '@/enums/ReviewType';

import campaignRouteParamsMixin from '@/mixins/campaignRouteParamsMixin';
import sidebarMixin from '@/mixins/sidebarMixin';

import vError from '@/components/VError';

export default {
  components: {
    Datepicker,
    vError,
    vSelect,
  },
  mixins: [campaignRouteParamsMixin, sidebarMixin],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    reviewPlanning: {
      type: Array,
      default: () => [],
    },
    reviewPlanningId: {
      type: [Number, null],
      default: null,
    },
  },
  data() {
    return {
      dataId: null,
      dataTypeOfReview: null,
      dataActivity: '',
      dataBudget: 0,
      dataStartDate: new Date(),
      dataEndDate: new Date(),
      dataReviewsStart: 0,
      dataReviewsTarget: 0,
      dataSessionsOnPdpLast30Days: 0,
      dataSoldLast30Days: 0,
      dataProductPrice: 0,
      dataUpliftCr: 0,
      reviewTypeList,
    };
  },
  computed: {
    campaignProductId() {
      return +this.$route.params.productId;
    },
    isFormValid() {
      return (
        !this.errors.any() &&
        this.dataTypeOfReview &&
        this.dataBudget &&
        this.dataStartDate &&
        this.dataEndDate &&
        this.dataReviewsStart &&
        this.dataReviewsTarget &&
        this.dataSessionsOnPdpLast30Days &&
        this.dataSoldLast30Days &&
        this.dataProductPrice &&
        this.dataUpliftCr
      );
    },
  },
  watch: {
    active(val) {
      if (!val) {
        return;
      }

      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        const {
          id,
          type_of_review,
          activity,
          budget,
          start_date,
          end_date,
          reviews_start,
          reviews_target,
          sessions_on_pdp_last_30_days,
          sold_last_30_days,
          product_price,
          uplift_cr,
        } = this.data;

        this.dataId = id;
        this.dataTypeOfReview = getReviewByValue(type_of_review);
        this.dataActivity = activity;
        this.dataBudget = budget;
        this.dataStartDate = start_date;
        this.dataEndDate = end_date;
        this.dataReviewsStart = reviews_start;
        this.dataReviewsTarget = reviews_target;
        this.dataSessionsOnPdpLast30Days = sessions_on_pdp_last_30_days;
        this.dataSoldLast30Days = sold_last_30_days;
        this.dataProductPrice = product_price;
        this.dataUpliftCr = uplift_cr;

        this.initValues();
      }
    },
  },
  methods: {
    ...mapActions('campaign', [
      CampaignAction.addReviewPlanningItem,
      CampaignAction.updateReviewPlanningItem,
    ]),

    initValues() {
      if (this.data.id) {
        return;
      }

      this.dataId = null;
      this.dataTypeOfReview = null;
      this.dataActivity = '';
      this.dataBudget = 0;
      this.dataStartDate = new Date();
      this.dataEndDate = new Date();
      this.dataReviewsStart = 0;
      this.dataReviewsTarget = 0;
      this.dataSessionsOnPdpLast30Days = 0;
      this.dataSoldLast30Days = 0;
      this.dataProductPrice = 0;
      this.dataUpliftCr = 0;
    },
    async handleSubmit() {
      if (!(await this.$validator.validateAll())) {
        return;
      }

      const obj = this.addProperIdToSubmitObject({
        id: this.dataId,
        review_planning_id: this.reviewPlanningId,
        type_of_review: this.dataTypeOfReview.value,
        activity: this.dataActivity,
        budget: this.dataBudget,
        start_date: this.dataStartDate,
        end_date: this.dataEndDate,
        reviews_start: this.dataReviewsStart,
        reviews_target: this.dataReviewsTarget,
        sessions_on_pdp_last_30_days: this.dataSessionsOnPdpLast30Days,
        sold_last_30_days: this.dataSoldLast30Days,
        product_price: this.dataProductPrice,
        uplift_cr: this.dataUpliftCr,
      });

      this.$vs.loading();

      if (this.shouldUpdate) {
        try {
          const item = await this.updateReviewPlanningItem(obj);
          this.$emit('update', item);

          this.notifySuccess(
            'Review Planning item updated',
            'The review planning item has been successfully updated.'
          );
        } catch (error) {
          this.notifyError(
            error,
            'Problem with updating a review planning item.'
          );
        }
      } else {
        delete obj.id;

        try {
          const item = await this.addReviewPlanningItem(obj);
          this.$emit('create', item);

          this.notifySuccess(
            'Review Planning item added',
            'The review planning item has been successfully added.'
          );
        } catch (error) {
          this.notifyError(
            error,
            'Problem with adding a review planning item.'
          );
        }
      }

      this.$vs.loading.close();
      this.$emit('close');
      this.initValues();
    },
  },
};
</script>
